import { useRecoilValue } from 'recoil';
import { useTranslation } from '@wojtekmaj/react-t';

import { Wrapper, Link } from './header.styles';

import RewardoLogo from '../../common/rewardo_logo';
import Avatar from '../../common/avatar';

import { currentUserState } from '../../../recoil';

export default function Header() {
  const currentUser = useRecoilValue(currentUserState);
  const profileString = useTranslation('Profile');

  return (
    <Wrapper>
      <RewardoLogo />
      <Link aria-label={profileString} to="/profile">
        <Avatar user={currentUser} size={40} />
      </Link>
    </Wrapper>
  );
}
