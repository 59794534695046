import type { SerializableParam } from 'recoil';

function getId(args: SerializableParam): string | null {
  return typeof args === 'string'
    ? args
    : typeof args === 'object' && args && 'id' in args
      ? args.id?.toString() ?? null
      : null;
}

export default function defaultGetActionExt({ args }: { args?: SerializableParam }) {
  const id = getId(args);

  return `/${id}`;
}
