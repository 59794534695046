import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { subscribe, subscribeToAll, unsubscribeFromAll } from '../utils/notifications';

import { maybeCurrentUserState } from '../recoil';

import type { NotificationPayload } from '@rewardopl/types';

type EventPayload = {
  notification: {
    data: NotificationPayload;
  };
  source: 'push-handler';
  type: 'notification-click';
};

export default function PushHandler() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useRecoilState(maybeCurrentUserState);

  // Automatically subscribe user to all notifications
  useEffect(() => {
    if (!currentUser || currentUser.notifications) {
      return;
    }

    subscribeToAll(currentUser).then(setCurrentUser);
  }, [currentUser, setCurrentUser]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const hasSubscribedToAnyCategory =
      currentUser.notifications && Object.values(currentUser.notifications).some(Boolean);

    if (hasSubscribedToAnyCategory) {
      subscribe(currentUser)
        .then(setCurrentUser)
        .catch(() => {
          // Intentionally empty
        });
    } else {
      unsubscribeFromAll(currentUser)
        .then(setCurrentUser)
        .catch(() => {
          // Intentionally empty
        });
    }
  }, [currentUser, setCurrentUser]);

  useEffect(() => {
    if (!navigator.serviceWorker) {
      return;
    }

    function handleNotificationClick(data: EventPayload) {
      const { notification } = data;

      switch (notification.data.category) {
        case 'messages': {
          const { message_id } = notification.data;
          const url = `/inbox/${message_id}`;
          navigate(url);
          break;
        }
        case 'products': {
          const { card_subscription_id, place_id } = notification.data;
          const url = `/card_subscriptions/${card_subscription_id}/places/${place_id}`;
          navigate(url);
          break;
        }
        case 'general':
        default: {
          const url = '/';
          navigate(url);
          break;
        }
      }
    }

    function onMessage(event: MessageEvent<EventPayload>) {
      const { data } = event;

      if (data.source === 'push-handler') {
        switch (data.type) {
          case 'notification-click':
            handleNotificationClick(data);
            break;
          default:
            throw new Error(`Unknown push handler message type: ${data.type satisfies never}`);
        }
      }
    }

    navigator.serviceWorker.addEventListener('message', onMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', onMessage);
    };
  }, [navigate]);

  return null;
}
