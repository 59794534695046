import rewardoLogo from '@rewardopl/assets/logos/rewardo.svg';

import { Wrapper, Image } from './rewardo_logo.styles';

type RewardoLogoProps = { width?: number };

export default function RewardoLogo({ width = 102 }: RewardoLogoProps) {
  const height = width * (23 / 102);

  return (
    <Wrapper>
      <Image width={width} height={height} src={rewardoLogo} alt="Rewardo" />
    </Wrapper>
  );
}
