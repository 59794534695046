import { useOnLine } from '@wojtekmaj/react-hooks';

type OnlineMonitorProps = {
  children: React.ReactElement;
  fallback: React.ReactElement;
};

export default function OnlineMonitor({ children, fallback }: OnlineMonitorProps) {
  const onLine = useOnLine();

  return onLine === false ? fallback : children;
}
