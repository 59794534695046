import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { T, useTranslation } from '@wojtekmaj/react-t';

import { toast } from '@rewardopl/react-toast';

import LinkButton from '@rewardopl/react-ui/link_button';

import { post } from '@rewardopl/utils/network';
import { stringify } from '@rewardopl/utils/url';

import { FormWrapper, Main, PageWrapper } from '../../login/index.styles';
import { ButtonWrapper } from '../../login/main/index.styles';

import LoadingText from '../../../loading_text';
import Heading from '../../../heading';
import Header from '../../../header';
import Footer from '../../../footer';

import { maybeCurrentUserState } from '../../../../recoil';

import { APP_ID } from '../../../../constants';

import type { User } from '@rewardopl/types';

function ContinueInApp() {
  const location = useLocation();

  return (
    <PageWrapper>
      <Header />
      <FormWrapper>
        <Main>
          <Heading align="center">Continue in app</Heading>
          <ButtonWrapper>
            <LinkButton
              as="a"
              className="primary"
              // @ts-expect-error - see https://github.com/styled-components/styled-components/issues/4112
              href={`rewardo-app://${location.pathname}${location.search}`}
            >
              <T>Continue in app</T>
            </LinkButton>
          </ButtonWrapper>
        </Main>
      </FormWrapper>
      <Footer />
    </PageWrapper>
  );
}

type AuthCallbackRedirectProps = {
  code: string;
  returnPath: string;
  state: string | null;
};

let didSend = false;

function AuthCallbackRedirect({ code, returnPath, state }: AuthCallbackRedirectProps) {
  const { provider } = useParams();
  const navigate = useNavigate();
  const setCurrentUser = useSetRecoilState(maybeCurrentUserState);
  const loginErrorString = useTranslation('Failed to log in');

  const action = `/api/auth/${provider}/callback${stringify({
    state: state ? encodeURIComponent(state) : '',
  })}`;

  useEffect(() => {
    if (didSend) {
      return;
    }

    didSend = true;

    (async () => {
      try {
        const user = (await post(action, {
          headers: {
            'app-id': APP_ID,
          },
          body: { code },
        })) as User;

        setCurrentUser(user);

        navigate(returnPath);
      } catch {
        toast.error(loginErrorString);

        navigate('/login');
      } finally {
        didSend = false;
      }
    })();
  }, [action, code, loginErrorString, navigate, returnPath, setCurrentUser]);

  return <LoadingText />;
}

export default function AuthCallback() {
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');

  if (!code) {
    return <Navigate to="/login" />;
  }

  const state = searchParams.get('state');

  const decodedState = (state ? JSON.parse(decodeURIComponent(state)) : null) as {
    returnPath?: string;
    mobile?: boolean;
  } | null;
  const returnPath = decodedState?.returnPath || '/';
  const isMobileLogin = decodedState?.mobile;

  if (isMobileLogin) {
    return <ContinueInApp />;
  }

  return <AuthCallbackRedirect code={code} returnPath={returnPath} state={state} />;
}
