import { Wrapper } from './index.styles';

import Nav from './nav';
import Content from './content';
import WebsocketHandler from './websocket_handler';

export default function IndexPage() {
  return (
    <Wrapper>
      <Nav />
      <Content />
      <WebsocketHandler />
    </Wrapper>
  );
}
