import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import CheckboxField from '@rewardopl/react-ui/checkbox_field';
import InputField from '@rewardopl/react-ui/input_field';

import { post } from '@rewardopl/utils/network';

import { Form, ButtonWrapper } from '../login/email/form.styles';

import TermsAndConditionsLink from '../../terms_and_conditions_link';
import PrivacyPolicyLink from '../../privacy_policy_link';

import { maybeCurrentUserState } from '../../../recoil';

import { APP_ID } from '../../../constants';

import type { User } from '@rewardopl/types';

const pendingConfig = {
  disabled: true,
};

export default function RegisterForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const setCurrentUser = useSetRecoilState(maybeCurrentUserState);
  const registerErrorString = useTranslation('Failed to register');
  const userAlreadyExistsString = useTranslation('User already exists');

  const { returnPath = '/' } = (location.state || {}) as { returnPath?: string };

  const action = '/api/users';

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    const formData = new FormData(form);

    try {
      const user = (await post(action, {
        headers: {
          'app-id': APP_ID,
        },
        body: formData,
      })) as User;

      setCurrentUser(user);
    } catch (error) {
      if (error instanceof Error && error.message === 'User already exists') {
        toast.error(userAlreadyExistsString);
        navigate('/login/email', { state: { email: formData.get('email'), returnPath } });
        return;
      }

      toast.error(registerErrorString);
    }
  }

  return (
    <Form action={action} method="POST">
      <InputField
        autoComplete="username"
        label="Email"
        name="email"
        placeholder="email@example.com"
        type="email"
      />
      <InputField
        autoComplete="new-password"
        label="Password"
        name="password"
        placeholder="••••••••"
        type="password"
      />
      <CheckboxField
        name="terms_accepted"
        label={
          <>
            <T>I have read and agree to the</T> <TermsAndConditionsLink /> <T>and</T>{' '}
            <PrivacyPolicyLink />.
          </>
        }
        required
      />
      <ButtonWrapper>
        <AsyncButton
          as={Button}
          className="primary"
          onClick={onClick}
          pendingConfig={pendingConfig}
          type="submit"
        >
          <T>Register</T>
        </AsyncButton>
      </ButtonWrapper>
    </Form>
  );
}
