import styled from 'styled-components';
import { alpha } from '@wojtekmaj/color-utils';

import {
  Button as CommonButton,
  buttonFocusStyles,
  buttonHoverStyles,
} from '@rewardopl/react-ui/button/index.styles';

const fbColors = {
  primary: '#1877f2',
  primaryDark: '#0c64b6',
};

export const Button = styled(CommonButton)`
  --fb-primary: ${fbColors.primary};
  --fb-dark: ${fbColors.primaryDark};
  --fb-primary-1: 0px 2px 2px ${alpha(fbColors.primary, 0.05)},
    0px 5px 10px ${alpha(fbColors.primary, 0.1)};
  display: inline-block;
  padding: 10px 14px;
  border: 0;
  background-color: var(--fb-primary);
  box-shadow: var(--fb-primary-1);
  color: white;

  &:hover {
    ${buttonHoverStyles}
    background-color: var(--fb-dark);
  }

  &:focus {
    ${buttonFocusStyles}
    box-shadow: 0px 0px 10px ${alpha(fbColors.primary, 0.6)};
    color: white;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  vertical-align: -26%;
`;
