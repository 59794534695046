import T from '@wojtekmaj/react-t';
import { useLocation } from 'react-router-dom';

import { stringify } from '@rewardopl/utils/url';

import { Button, Icon } from './login_with_facebook_button.styles';

import facebookIcon from '../../../../static/facebook.svg';

export default function LoginWithFacebookButton() {
  const location = useLocation();

  const { returnPath = '/' } = (location.state || {}) as {
    returnPath?: string;
  };

  return (
    <Button
      as="a"
      href={`/api/auth/facebook${stringify({
        state: encodeURIComponent(JSON.stringify({ mobile: false, returnPath })),
      })}`}
    >
      <Icon src={facebookIcon} width={20} height={20} alt="Facebook" />
      <T>Login with Facebook</T>
    </Button>
  );
}
