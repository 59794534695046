import { ContentWrapper } from './contact_us.styles';

export default function ContactUs() {
  return (
    <ContentWrapper>
      <p>
        Rewardo sp. z o.o.
        <br />
        Aleje Zygmunta Krasińskiego 14/3a
        <br />
        30-101 Kraków
      </p>

      <p>
        NIP 6772457843
        <br />
        KRS 0000866233
        <br />
        REGON 38736484800000
      </p>

      <p>
        <strong>Konto bankowe do przelewów:</strong>
        <br />
        mBank S.A. 31 1140 2004 0000 3902 8059 9509
      </p>

      <h3>Dane kontaktowe</h3>

      <p>
        <strong>Dział obsługi klienta:</strong>
        <br />
        Adres email: <a href="mailto:bok@rewardoapp.com">bok@rewardoapp.com</a>
      </p>

      <p>
        <strong>Dział sprzedaży:</strong>
        <br />
        Sebastian Kasza
        <br />
        Adres email:{' '}
        <a href="mailto:sebastian.kasza@rewardoapp.com">sebastian.kasza@rewardoapp.com</a>
        <br />
        Numer telefonu: <a href="tel:+48698661453">+48 698 661 453</a>
      </p>
    </ContentWrapper>
  );
}
