import styled from 'styled-components';

import {
  Button as CommonButton,
  buttonFocusStyles,
  buttonHoverStyles,
} from '@rewardopl/react-ui/button/index.styles';

export const Button = styled(CommonButton)`
  display: inline-block;
  background-color: #fff;
  border-color: #747775;
  box-shadow:
    0px 2px 2px rgba(0, 0, 0, 0.05),
    0px 5px 10px rgba(0, 0, 0, 0.1);
  color: black;

  &:hover {
    ${buttonHoverStyles}
    background-color: #eee;
    color: black;
  }

  &:focus {
    ${buttonFocusStyles}
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  position: relative;
  top: -1px;
  margin-right: 12px;
  vertical-align: -26%;
`;
