import T from '@wojtekmaj/react-t';

import { Wrapper, ImageContainer, Image, Text } from './slide.styles';

type SlideProps = {
  fetchPriority?: 'high' | 'low' | 'auto';
  image: string;
  text: string;
};

export default function Slide({ fetchPriority, image, text }: SlideProps) {
  return (
    <Wrapper>
      <ImageContainer>
        <Image alt="" src={image} width={375} height={404} fetchPriority={fetchPriority} />
      </ImageContainer>
      <Text>
        <T>{text}</T>
      </Text>
    </Wrapper>
  );
}
