import { useRef, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Helmet } from 'react-helmet-async';
import T from '@wojtekmaj/react-t';

import Button from '@rewardopl/react-ui/button';
import LinkButton from '@rewardopl/react-ui/link_button';

import colors from '@rewardopl/styles/colors';

import { PageWrapper, ContentWrapper, Title, ButtonWrapper } from './index.styles';

import ScrollableSlides from './scrollable_slides';
import Slide from './slide';

import { scrollToChild } from '../../../utils/carousel';

import { configLoginState } from '../../../recoil';

import onboarding1 from '../../../static/onboarding/onboarding-1.svg';
import onboarding2 from '../../../static/onboarding/onboarding-2.svg';
import onboarding3 from '../../../static/onboarding/onboarding-3.svg';
import onboarding4 from '../../../static/onboarding/onboarding-4.svg';
import onboarding5 from '../../../static/onboarding/onboarding-5.svg';

const slides = [
  {
    id: 1,
    fetchPriority: 'high',
    image: onboarding1,
    text: 'Welcome to Rewardo!',
  },
  {
    id: 2,
    image: onboarding2,
    text: 'Shop in your favorite places',
  },
  {
    id: 3,
    image: onboarding3,
    text: 'Collect stamps or points',
  },
  {
    id: 4,
    image: onboarding4,
    text: 'Exchange for rewards',
  },
  {
    id: 5,
    image: onboarding5,
    text: 'Take part in challenges',
  },
] as const;

export default function OnboardingPage() {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [currentChildIndex, onChildChange] = useState(0);
  useRecoilValueLoadable(configLoginState);

  function scrollToNext() {
    if (!scrollContainer.current) {
      return;
    }

    scrollToChild(currentChildIndex + 1, scrollContainer.current);
  }

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={colors['gray-light']} />
      </Helmet>
      <PageWrapper>
        <ContentWrapper>
          <Title>
            <T>Welcome to Rewardo!</T>
          </Title>
          <ScrollableSlides scrollContainer={scrollContainer} onChildChange={onChildChange}>
            {slides.map((slide) => (
              <Slide key={slide.id} {...slide} />
            ))}
          </ScrollableSlides>
          <ButtonWrapper>
            {currentChildIndex === slides.length - 1 ? (
              <LinkButton to="/login" className="primary" key="begin">
                <T>Let’s begin</T>
              </LinkButton>
            ) : (
              <>
                <LinkButton to="/login" key="skip">
                  <T>Skip</T>
                </LinkButton>
                <Button className="primary" onClick={scrollToNext} type="button">
                  <T>Next</T>
                </Button>
              </>
            )}
          </ButtonWrapper>
        </ContentWrapper>
      </PageWrapper>
    </>
  );
}
