import { Wrapper } from './nav.styles';

import RewardoLogo from '../../common/rewardo_logo';
import RegisterBanner from './register_banner';
import Menu from './menu';

export default function Nav() {
  return (
    <Wrapper>
      <RewardoLogo width={133} />
      <RegisterBanner />
      <Menu />
    </Wrapper>
  );
}
