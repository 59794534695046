import { expand, nearest } from '@rewardopl/utils/array';

/**
 * Finds current scroll percentage
 *
 * @param {HTMLElement} container Container
 * @returns {number} Scroll percentage
 */
export function getScrollPercentage(container: HTMLElement): number {
  return container.scrollLeft / (container.scrollWidth - container.clientWidth);
}

function isHTMLElement(element: Element): element is HTMLElement {
  return element instanceof HTMLElement;
}

/**
 * Finds the index of a child element closest to current scroll position
 *
 * @param {HTMLElement} container Container
 * @param {HTMLCollection} [children=container.children] Children
 * @returns {number} Index of a child element closest to current scroll position
 */
export function findClosestChild(
  container: HTMLElement,
  children: HTMLCollection = container.children,
): number {
  function findChildScrollPercentage(child: HTMLElement): number {
    return (child.offsetLeft + child.clientWidth / 2) / container.scrollWidth;
  }

  const containerChildren = Array.from(children).filter(isHTMLElement);
  const childPositions = expand(containerChildren.map(findChildScrollPercentage));
  const scrollPercentage = getScrollPercentage(container);
  const closestChildPosition = nearest(childPositions, scrollPercentage);

  if (!closestChildPosition) {
    return 0;
  }

  const closestChildIndex = childPositions.indexOf(closestChildPosition);

  return closestChildIndex;
}

export function scrollToChild(childIndex: number, scrollContainer: HTMLElement | null): void {
  if (!scrollContainer) {
    return;
  }

  const childrenArray = Array.from(scrollContainer.children);
  const child = childrenArray[childIndex];

  if (!child || !isHTMLElement(child)) {
    return;
  }

  scrollContainer.scrollTo({
    left: child.offsetLeft,
    behavior: 'smooth',
  });
}
