import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import NoAccessPage from './pages/no_access';

import { maybeCurrentUserState } from '../recoil';

import type { User } from '@rewardopl/types';

type AuthGateProps = {
  anyOf: ((user: User | null) => boolean)[];
  children: React.ReactElement;
};

export default function AuthGate({ anyOf, children }: AuthGateProps) {
  const location = useLocation();
  const currentUser = useRecoilValue(maybeCurrentUserState);

  const isAuthorized = anyOf.some((fn) => fn(currentUser));

  if (isAuthorized) {
    return children;
  }

  const { pathname, search } = location;

  const { returnPath = '/' } = (location.state || {}) as { returnPath?: string };

  const toPathname = (() => {
    if (currentUser) {
      return returnPath;
    }

    if (pathname === '/') {
      return '/onboarding';
    }

    return null;
  })();

  if (toPathname) {
    return <Navigate to={toPathname} replace />;
  }

  const state = { returnPath: pathname + search };

  return <NoAccessPage state={state} />;
}
