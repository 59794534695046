import styled from 'styled-components';

import { navigation } from '@rewardopl/styles/z-index';

import { Wrapper as RewardoLogo } from '../../common/rewardo_logo.styles';

export const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  grid-area: aside;
  padding: 0;
  padding-left: env(safe-area-inset-left);
  background: white;
  border-top: 1px solid #eee;
  position: relative;
  z-index: ${navigation};

  ${RewardoLogo} {
    display: none;
  }

  @media screen and (min-width: 769px) {
    width: 100%;
    padding: 15px;
    padding-left: max(15px, env(safe-area-inset-left));
    border-top: none;
    overflow: auto;

    ${RewardoLogo} {
      display: block;
      margin: 0 auto var(--gap);
    }
  }
`;
