import { useSearchParams } from 'react-router-dom';

import { stringify } from '@rewardopl/utils/url';

import type { NavigateOptions } from 'react-router-dom';

export default function useLocationParams(options?: NavigateOptions) {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = Object.fromEntries(searchParams);

  function onChange(nextParams: Record<string, string | number | boolean | null>) {
    const nextSearch = stringify(nextParams);

    setSearchParams(nextSearch, options);
  }

  return [params, onChange] as const;
}
