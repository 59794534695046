import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.nav`
  width: 100%;
  position: relative;
  margin: 0 auto;
  overflow: auto;
  user-select: none;

  @media screen and (min-width: 769px) {
    margin: 0;
    overflow: unset;
  }
`;

export const List = styled.ul`
  display: flex;
  list-style: none;
  justify-content: space-between;
  padding: 0 10px;
  gap: 10px;

  @media screen and (min-width: 769px) {
    flex-direction: column;
    padding: 0 5px;
  }
`;

export const ListItem = styled.li`
  flex-basis: min-content;
  min-width: 58px;
  max-width: 70px;
  height: 100%;
  text-align: center;

  &:empty {
    display: none;
  }

  @media screen and (min-width: 769px) {
    max-width: unset;
    width: auto;
    text-align: left;
  }
`;

export const ListItemLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 12px 0;
  color: inherit;
  text-decoration: none;
  font-size: 0.8em;
  gap: 5px;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.active {
    color: var(--primary);
  }

  @media screen and (min-width: 769px) {
    min-height: 40px;
    flex-direction: row;
    padding: 8px 12px 8px calc(var(--gap) - 10px);
    border-radius: 8px;
    font-size: inherit;
    transition: background-color ease-in-out 200ms;
    gap: 8px;

    &:hover,
    &:focus {
      background-color: var(--gray-light);
    }

    &.active {
      background-color: var(--primary-light);
      color: inherit;
    }
  }
`;

export const TabIndicator = styled.div`
  position: absolute;
  top: 0;
  width: 24px;
  height: 3px;
  margin: 0 !important;
  background: var(--primary);
  transform: translateX(-50%);

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const Icon = styled.span`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: inherit;
  line-height: 0;

  img,
  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 769px) {
    width: 15px;
    height: 15px;
  }
`;

export const Label = styled.span``;

export const Badge = styled.span`
  min-width: 25px;
  height: 19px;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0 4px;
  background: var(--primary);
  border-radius: var(--border-radius-round);
  color: white;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  @media screen and (min-width: 769px) {
    position: static;
    margin: -2px 0;
    margin-left: auto;
  }
`;
