import { Link, useLocation } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { PageWrapper, Main, FormWrapper } from '../login/index.styles';

import Heading from '../../heading';
import Header from '../../header';
import RegisterForm from './form';
import Footer from '../../footer';

export default function RegisterPage() {
  const location = useLocation();

  return (
    <PageWrapper>
      <Header />
      <FormWrapper>
        <Main>
          <Heading align="center">Register</Heading>
          <RegisterForm />
          <p>
            <T>Already have an account?</T>{' '}
            <Link to="/login" state={location.state}>
              <T>Login</T>
            </Link>
            .
          </p>
        </Main>
      </FormWrapper>
      <Footer />
    </PageWrapper>
  );
}
