import { supportsBarcodeDetector } from './utils/feature_detection';

if (!('scrollBehavior' in document.documentElement.style)) {
  import('smoothscroll-polyfill')
    .then((module) => {
      (module.default || module).polyfill();
    })
    .catch(() => {
      // Intentionally empty
    });
}

if (!supportsBarcodeDetector) {
  import('barcode-detector')
    .then((module) => {
      window.BarcodeDetector = module.default || module;
    })
    .catch(() => {
      // Intentionally empty
    });
}

if (!('ResizeObserver' in window)) {
  import('resize-observer-polyfill')
    .then((module) => {
      window.ResizeObserver = module.default || module;
    })
    .catch(() => {
      // Intentionally empty
    });
}
