import { useSetRecoilState } from 'recoil';
import T, { useTranslation } from '@wojtekmaj/react-t';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';

import { post } from '@rewardopl/utils/network';

import { maybeCurrentUserState } from '../../../../recoil';

import { APP_ID } from '../../../../constants';

import type { User } from '@rewardopl/types';

export default function SkipButton() {
  const setCurrentUser = useSetRecoilState(maybeCurrentUserState);
  const registerErrorString = useTranslation('Failed to register');

  async function onClickSkip() {
    try {
      const user = (await post('/api/users', {
        headers: {
          'app-id': APP_ID,
        },
      })) as User;

      setCurrentUser(user);
    } catch {
      toast.error(registerErrorString);
    }
  }

  return (
    <Button type="button" onClick={onClickSkip}>
      <T>Skip login</T>
    </Button>
  );
}
