import { Wrapper } from './header.styles';

import RewardoLogo from './common/rewardo_logo';

export default function Header() {
  return (
    <Wrapper>
      <RewardoLogo />
    </Wrapper>
  );
}
