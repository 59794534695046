import { Link, Navigate, useLocation } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { PageWrapper, Main, FormWrapper } from '../login/index.styles';

import Heading from '../../heading';
import Header from '../../header';
import Footer from '../../footer';

function getInboxData(domain: string): { name: string; link: string } | null {
  switch (domain) {
    case 'gmail.com':
      return {
        name: 'Gmail',
        link: 'https://mail.google.com',
      };
    case 'hotmail.com':
    case 'outlook.com':
      return {
        name: 'Outlook',
        link: 'https://outlook.live.com',
      };
    case 'icloud.com':
      return {
        name: 'iCloud',
        link: 'https://www.icloud.com/mail',
      };
    case 'interia.pl':
    case 'interia.eu':
    case 'poczta.fm':
      return {
        name: 'Poczta Interia',
        link: 'https://poczta.interia.pl',
      };
    case 'o2.pl':
    case 'tlen.pl':
      return {
        name: 'Poczta o2',
        link: 'https://poczta.o2.pl',
      };
    case 'onet.pl':
    case 'onet.eu':
    case 'poczta.onet.pl':
      return {
        name: 'Onet Poczta',
        link: 'https://poczta.onet.pl',
      };
    case 'wp.pl':
      return {
        name: 'WP Poczta',
        link: 'https://poczta.wp.pl',
      };
    case 'yahoo.com':
    case 'yahoo.pl':
      return {
        name: 'Yahoo',
        link: 'https://mail.yahoo.com',
      };
    default:
      return null;
  }
}

function getEmailDomain(email: string): string {
  const [/* username */ , domain] = email.split('@');

  if (!domain) {
    throw new Error(`Invalid email: ${email}`);
  }

  return domain;
}

export default function PasswordResetConfirmationPage() {
  const location = useLocation();

  const { email } = (location.state || {}) as { email?: string };

  if (!email) {
    return <Navigate to="/login" />;
  }

  const domain = getEmailDomain(email);
  const inboxData = getInboxData(domain);

  return (
    <PageWrapper>
      <Header />
      <FormWrapper>
        <Main>
          <Heading align="center">Link sent</Heading>
          <p>
            <T>Check your inbox and follow attached instructions.</T>
          </p>
          {inboxData ? (
            <p>
              <a href={inboxData.link} target="_blank" rel="noreferrer">
                <T name={inboxData.name}>{'Open {name}'}</T>
              </a>
            </p>
          ) : null}
          <p>
            <Link to="/login">
              <T>Login</T>
            </Link>
          </p>
        </Main>
      </FormWrapper>
      <Footer />
    </PageWrapper>
  );
}
