import styled from 'styled-components';

import { main } from '@rewardopl/styles/z-index';

export const Wrapper = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: scroll;
  scrollbar-color: var(--gray-dark) transparent;
  scrollbar-width: auto;
  position: relative;
  z-index: ${main};
  -webkit-overflow-scrolling: touch;
`;

export const Main = styled.main`
  flex-grow: 1;
  max-width: 100vw;
  position: relative;
  padding-right: env(safe-area-inset-right);
  background: var(--gray-light);

  @media screen and (min-width: 769px) {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
`;
