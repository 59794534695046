import { useNavigate } from 'react-router-dom';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import InputField from '@rewardopl/react-ui/input_field';

import { post } from '@rewardopl/utils/network';

import { Form, ButtonWrapper } from '../login/email/form.styles';

import { APP_ID } from '../../../constants';

const pendingConfig = {
  disabled: true,
};

export default function SendLinkForm() {
  const navigate = useNavigate();
  const sendLinkErrorString = useTranslation('Failed to request password reset');

  const action = '/api/users/password_reset';

  async function onClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    const formData = new FormData(form);

    try {
      await post(action, {
        headers: {
          'app-id': APP_ID,
        },
        body: formData,
      });

      navigate('/password_reset_confirmation', { state: { email: formData.get('email') } });
    } catch {
      toast.error(sendLinkErrorString);
    }
  }

  return (
    <Form action={action} method="POST">
      <InputField
        autoComplete="username"
        label="Email"
        name="email"
        placeholder="email@example.com"
        type="email"
      />
      <ButtonWrapper>
        <AsyncButton
          as={Button}
          className="primary"
          onClick={onClick}
          pendingConfig={pendingConfig}
          type="submit"
        >
          <T>Send link</T>
        </AsyncButton>
      </ButtonWrapper>
    </Form>
  );
}
