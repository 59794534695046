import { css, createGlobalStyle } from 'styled-components';

import rockfordRegularWoff from '@rewardopl/assets/fonts/rockford_regular.woff';
import rockfordRegularWoff2 from '@rewardopl/assets/fonts/rockford_regular.woff2';
import rockfordRegularItalicWoff from '@rewardopl/assets/fonts/rockford_regular_italic.woff';
import rockfordRegularItalicWoff2 from '@rewardopl/assets/fonts/rockford_regular_italic.woff2';
import rockfordMediumWoff from '@rewardopl/assets/fonts/rockford_medium.woff';
import rockfordMediumWoff2 from '@rewardopl/assets/fonts/rockford_medium.woff2';
import rockfordMediumItalicWoff from '@rewardopl/assets/fonts/rockford_medium_italic.woff';
import rockfordMediumItalicWoff2 from '@rewardopl/assets/fonts/rockford_medium_italic.woff2';

import colors from '@rewardopl/styles/colors';
import colorsRgb from '@rewardopl/styles/colors_rgb';
import gradients from '@rewardopl/styles/gradients';
import shadows from '@rewardopl/styles/shadows';
import variables from '@rewardopl/styles/variables';

const fonts = [
  {
    fontFamily: 'Rockford',
    fontStyle: 'regular',
    fontWeight: '400',
    src: [rockfordRegularWoff2, rockfordRegularWoff],
  },
  {
    fontFamily: 'Rockford',
    fontStyle: 'italic',
    fontWeight: '400',
    src: [rockfordRegularItalicWoff2, rockfordRegularItalicWoff],
  },
  {
    fontFamily: 'Rockford',
    fontStyle: 'regular',
    fontWeight: '500',
    src: [rockfordMediumWoff2, rockfordMediumWoff],
  },
  {
    fontFamily: 'Rockford',
    fontStyle: 'italic',
    fontWeight: '500',
    src: [rockfordMediumItalicWoff2, rockfordMediumItalicWoff],
  },
];

const fontStyle = css`
  ${fonts.map(
    (font) => css`
      @font-face {
        font-family: ${font.fontFamily};
        font-style: ${font.fontStyle};
        font-weight: ${font.fontWeight};
        font-display: swap;
        src: ${font.src.map((src) => `url(${src}) format('${src.split('.').pop()}')`).join(', ')};
      }
    `,
  )}
`;

const style = document.createElement('style');
style.innerHTML = fontStyle.join('');
document.head.appendChild(style);

export const linkStyle = css`
  color: var(--primary);
  font: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &.primary {
    font: inherit;
    font-weight: 500;

    &:hover,
    &:focus {
      color: var(--primary-dark);
      text-decoration: none;
    }
  }
`;

function addUnit(value: string | number): string {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  return value;
}

const vars = css`
  ${Object.entries({
    ...colors,
    ...gradients,
    ...shadows,
  }).map(([name, value]) => `--${name}: ${value};`)}
`;

const unitVars = css`
  ${Object.entries(variables).map(([name, value]) => `--${name}: ${addUnit(value)};`)}
`;

const rgbVars = css`
  ${Object.entries(colorsRgb).map(([name, value]) => `--${name}-rgb: ${value};`)}
`;

export const GlobalStyle = createGlobalStyle`${css`
  :root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;

    ${vars}
    ${unitVars}
    ${rgbVars}
  }

  body {
    min-height: 100vh;
    line-height: 1.333;
    margin: 0;
    overflow: hidden;
    color: var(--text);
    font-family: Rockford, sans-serif;
    font-size: 14px;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-weight: 500;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  a {
    ${linkStyle}
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  button {
    margin: 0;
    cursor: pointer;
  }

  hr {
    width: 100%;
    border: 0;
    border-top: 1px solid gray;
  }

  label {
    display: block;
  }

  table {
    border-collapse: separate;
    border-spacing: 24px 10px;
    margin: -10px -24px;

    th {
      margin-bottom: 4px;
      text-align: left;
      font-weight: 500;
      vertical-align: top;

      @media screen and (min-width: 769px) {
        margin-bottom: 0;
      }
    }

    th,
    td {
      display: block;

      @media screen and (min-width: 769px) {
        display: table-cell;
      }
    }

    tr:not(:first-child) {
      th {
        margin-top: 10px;

        @media screen and (min-width: 769px) {
          margin-top: 0;
        }
      }
    }
  }
`}`;
