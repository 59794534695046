import { Wrapper, Image } from './splash_screen.styles';

import logo from '../static/icons/logo.svg';

export default function SplashScreen() {
  return (
    <Wrapper>
      <Image src={logo} alt="Rewardo" />
    </Wrapper>
  );
}
