import { Children, useCallback, useState } from 'react';

import { Wrapper, List, ListItem } from './scrollable_slides.styles';

import CarouselNavigation from '../../carousel_navigation';

import { findClosestChild } from '../../../utils/carousel';

type ScrollableSlidesProps = {
  children: React.ReactNode;
  onChildChange?: (index: number) => void;
  scrollContainer: React.RefObject<HTMLDivElement>;
};

export default function ScrollableSlides({
  children,
  onChildChange,
  scrollContainer,
}: ScrollableSlidesProps) {
  const [currentChildIndex, setCurrentChildIndex] = useState(0);
  const numberOfChildren = Children.count(children);

  const onScroll = useCallback(() => {
    if (!scrollContainer.current) {
      return;
    }

    const closestChildIndex = findClosestChild(scrollContainer.current);

    setCurrentChildIndex(closestChildIndex);
    if (onChildChange) {
      onChildChange(closestChildIndex);
    }
  }, [onChildChange, scrollContainer]);

  return (
    <Wrapper>
      <List onScroll={onScroll} ref={scrollContainer} tabIndex={0}>
        {Children.map(children, (child, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: index is stable here
          <ListItem key={index}>{child}</ListItem>
        ))}
      </List>
      <CarouselNavigation
        currentChildIndex={currentChildIndex}
        numberOfChildren={numberOfChildren}
        scrollContainer={scrollContainer}
      />
    </Wrapper>
  );
}
