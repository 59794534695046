import styled from 'styled-components';

export const ContentWrapper = styled.div`
  text-align: left;
  line-height: 1.5;

  h3 {
    margin: 1em 0;
  }

  ol,
  ul {
    padding-left: 1.5em;
    list-style-position: outside;

    & ol,
    & ul {
      margin-left: 1em;
    }
  }
`;
