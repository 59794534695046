import { lazy, Suspense } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import Dialog from '@rewardopl/react-ui/dialog';

import LoadingText from './loading_text';

const TermsAndConditions = lazy(() => import('./terms_and_conditions'));

export default function TermsAndConditionsLink() {
  const location = useLocation();
  const navigate = useNavigate();

  const close = () => {
    navigate(
      {
        ...location,
        hash: '',
      },
      { state: location.state },
    );
  };

  return (
    <>
      <Link to="#terms-and-conditions">
        <T>Terms and conditions</T>
      </Link>
      <Dialog
        isOpen={location.hash === '#terms-and-conditions'}
        onDismiss={close}
        title="Terms and conditions"
      >
        <Suspense fallback={<LoadingText />}>
          <TermsAndConditions />
        </Suspense>
      </Dialog>
    </>
  );
}
