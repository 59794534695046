import T from '@wojtekmaj/react-t';
import { useLocation } from 'react-router-dom';

import { stringify } from '@rewardopl/utils/url';

import { Button, Icon } from './login_with_apple_button.styles';

import appleIcon from '../../../../static/apple.svg';

export default function LoginWithAppleButton() {
  const location = useLocation();

  const { returnPath = '/' } = (location.state || {}) as {
    returnPath?: string;
  };

  return (
    <Button
      as="a"
      href={`/api/auth/apple${stringify({
        state: encodeURIComponent(JSON.stringify({ mobile: false, returnPath })),
      })}`}
    >
      <Icon src={appleIcon} width={20} height={20} alt="Apple" />
      <T>Sign in with Apple</T>
    </Button>
  );
}
