import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type ScrollToTopProps = {
  wrapper: React.RefObject<HTMLElement | null>;
};

export default function ScrollToTop({ wrapper }: ScrollToTopProps) {
  const location = useLocation();

  useEffect(() => {
    const { scrollToTop = true } = (location.state || {}) as { scrollToTop?: boolean };

    if (!scrollToTop || !wrapper.current) {
      return;
    }

    wrapper.current.scrollTo(0, 0);
  }, [location, wrapper]);

  return null;
}
