import T from '@wojtekmaj/react-t';

import { PageWrapper, ContentWrapper, Image, Title, Sub } from './index.styles';

import RewardoLogo from '../../common/rewardo_logo';

import wifiIcon from '../../../static/wifi.svg';

export default function OfflinePage() {
  return (
    <PageWrapper>
      <ContentWrapper>
        <RewardoLogo width={164} />
        <Image width={123} height={123} src={wifiIcon} alt="" />
        <Title>
          <T>You’re offline</T>
        </Title>
        <Sub>
          <T>Check if you paid the internet bill and try again.</T>
        </Sub>
      </ContentWrapper>
    </PageWrapper>
  );
}
