import styled, { css } from 'styled-components';

export const Wrapper = styled.nav`
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 8px;
`;

export const Dot = styled.button<{ current?: boolean }>`
  width: 8px;
  height: 8px;
  padding: 0;
  border: 0;
  border-radius: var(--border-radius-round);
  background: rgba(var(--black-rgb), 0.25);
  ${(props) =>
    props.current &&
    css`
      background: var(--secondary-dark);
    `}

  &::-moz-focus-inner {
    border: 0;
  }
`;
