import { Link } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { Wrapper } from './register_banner.styles';

import useIsRegistered from '../../../hooks/useIsRegistered';

export default function RegisterBanner() {
  const isRegistered = useIsRegistered();

  if (isRegistered) {
    return null;
  }

  return (
    <Wrapper>
      <p>
        <T>To enjoy all the benefits</T>
      </p>
      <Link to="/profile/register">
        <T>Register</T>
      </Link>
    </Wrapper>
  );
}
