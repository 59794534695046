import { getUserLocales } from 'get-user-locale';
import { getMatchingLocale } from '@wojtekmaj/react-t/dist/esm/utils/locale';

export const defaultLocale = 'en';

export const languageFiles = {
  de: () => import('./de.json'),
  pl: () => import('./pl.json'),
  uk: () => import('./uk.json'),
};

export const supportedLocales = [defaultLocale].concat(Object.keys(languageFiles));

const locales = getUserLocales();

export const locale = (getMatchingLocale(locales, supportedLocales) || defaultLocale) as
  | 'de'
  | 'en'
  | 'pl'
  | 'uk';
