import { Suspense, lazy, useRef } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Wrapper, Main } from './content.styles';

import LoadingText from '../../loading_text';
import ErrorBoundary from '../../error_boundary';

import Title from '../../title';
import ScrollToTop from '../../scroll_to_top';
import Header from './header';

const HomePage = lazy(() => import('./home'));
const MapPage = lazy(() => import('./map'));
const InboxPage = lazy(() => import('./inbox'));
const ChallengesPage = lazy(() => import('./challenges'));
const CardSubscriptionsPage = lazy(() => import('./card_subscriptions'));
const ProfilePage = lazy(() => import('./profile'));
const CameraPage = lazy(() => import('./camera'));
const RedeemPage = lazy(() => import('./redeem'));

const PWAPopover = lazy(() => import('./pwa_popover'));

export default function Content() {
  const wrapper = useRef<HTMLDivElement>(null);

  return (
    <>
      <ErrorBoundary fallback={null}>
        <Suspense fallback={null}>
          <PWAPopover />
        </Suspense>
      </ErrorBoundary>
      <Wrapper ref={wrapper}>
        <Title />
        <ScrollToTop wrapper={wrapper} />
        <Routes>
          <Route path="" element={<Header />} />
          <Route path="inbox" element={<Header />} />
          <Route path="challenges" element={<Header />} />
        </Routes>
        <Main>
          <Suspense fallback={<LoadingText />}>
            <Routes>
              <Route path="" element={<HomePage />} />
              <Route path="map/*" element={<MapPage />} />
              <Route path="inbox/*" element={<InboxPage />} />
              <Route path="challenges/*" element={<ChallengesPage />} />
              <Route path="card_subscriptions/*" element={<CardSubscriptionsPage />} />
              <Route path="profile/*" element={<ProfilePage />} />
              <Route path="camera/*" element={<CameraPage />} />
              <Route path="redeem/*" element={<RedeemPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </Main>
      </Wrapper>
    </>
  );
}
