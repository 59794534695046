import {
  createState as commonCreateState,
  createSimpleState as commonCreateSimpleState,
} from '@rewardopl/recoil';

import defaultGetAction from './recoil/default_get_action';
import defaultGetActionExt from './recoil/default_get_action_ext';

import type { GetRecoilValue, RecoilState, SerializableParam } from 'recoil';
import type { BaseRecord } from '@rewardopl/recoil/types';

type GetAction<P extends SerializableParam = string> = ({
  args,
  get,
  keys,
  scopedRoute,
}: {
  args?: string | P;
  get: GetRecoilValue;
  keys: string;
  scopedRoute: boolean;
}) => string;

type GetActionExt<P extends SerializableParam = string> = ({
  args,
}: {
  args?: string | P;
}) => string;

type Options<P extends SerializableParam = string> = {
  getAction?: GetAction<P>;
  getActionExt?: GetActionExt<P>;
  scopedRoute?: boolean;
};

export function createState<T extends BaseRecord, P extends SerializableParam = string>(
  key: string,
  { getAction = defaultGetAction, getActionExt = defaultGetActionExt, ...options }: Options<P> = {},
): {
  itemsQuery: (param: P) => RecoilState<T[]>;
  itemQuery: (param: P) => RecoilState<T>;
} {
  return commonCreateState<T, P>(key, { ...options, getAction, getActionExt });
}

export function createSimpleState<T extends BaseRecord, P extends SerializableParam = string>(
  key: string,
  { getAction = defaultGetAction, getActionExt = defaultGetActionExt, ...options }: Options<P> = {},
): {
  itemsState: RecoilState<T[]>;
  itemQuery: (param: P) => RecoilState<T>;
} {
  return commonCreateSimpleState<T, P>(key, { ...options, getAction, getActionExt });
}
