import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import T from '@wojtekmaj/react-t';

import LinkButton from '@rewardopl/react-ui/link_button';

import { ButtonWrapper } from './index.styles';

import LoginWithAppleButton from './login_with_apple_button';
import LoginWithFacebookButton from './login_with_facebook_button';
import LoginWithGoogleButton from './login_with_google_button';
import SkipButton from './skip_button';

import { configLoginState } from '../../../../recoil';

export default function LoginMain() {
  const location = useLocation();
  const configLogin = useRecoilValue(configLoginState);

  const { state } = location;
  const { apple, google, facebook, email, skip } = configLogin;

  return (
    <ButtonWrapper>
      {apple ? <LoginWithAppleButton /> : null}
      {google ? <LoginWithGoogleButton /> : null}
      {facebook ? <LoginWithFacebookButton /> : null}
      {email ? (
        <LinkButton className="primary" to="email" state={state}>
          <T>Login with email</T>
        </LinkButton>
      ) : null}
      {skip ? <SkipButton /> : null}
    </ButtonWrapper>
  );
}
