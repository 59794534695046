import styled from 'styled-components';

export const headerHeight = 60;

export const Wrapper = styled.header`
  min-height: ${headerHeight}px;
  padding: calc((var(--gap) / 2) - 2px) var(--gap) calc((var(--gap) / 2) + 2px);
  display: flex;
  align-items: center;
  gap: var(--gap);
`;
