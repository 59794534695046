import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import * as Sentry from '@sentry/react';

import { get as networkGet } from '@rewardopl/utils/network';
import { stringify } from '@rewardopl/utils/url';

import { createSimpleState, createState } from './utils/recoil';

import { APP_ID } from './constants';

import { PROD } from './env';

import type { AtomEffect } from 'recoil';
import type {
  ActiveCardSubscription,
  Business,
  Card,
  CardSubscription,
  Challenge,
  ConfigLogin,
  CouponWithDynamicProperties,
  HeavilyFilteredUser,
  Message,
  NotRedeemedRewardTransaction,
  Offer,
  Place,
  Product,
  Reward,
  RewardTransaction,
  Transaction,
  UnreadMessage,
  User,
} from '@rewardopl/types';

type ChallengeRanking = { user: HeavilyFilteredUser; score: number }[];

const isProduction = PROD;

function localStorageEffect<T>(key: string): AtomEffect<T | null> {
  return ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue !== null) {
      const parsedSavedValue = JSON.parse(savedValue) as T | null;

      setSelf(parsedSavedValue);

      if (key === 'user') {
        if (parsedSavedValue) {
          if (isProduction) {
            Sentry.setUser({
              id: (parsedSavedValue as T as User)._id,
              email: (parsedSavedValue as T as User).email,
            });
          }
        } else {
          if (isProduction) {
            Sentry.setUser(null);
          }
        }
      }
    }

    onSet((newValue) => {
      if (newValue !== undefined) {
        localStorage.setItem(key, JSON.stringify(newValue));
      } else {
        localStorage.removeItem(key);
      }
    });
  };
}

const sentryEffect: AtomEffect<User | null> = ({ onSet }) => {
  onSet((newValue) => {
    if (newValue) {
      if (isProduction) {
        Sentry.setUser({
          id: newValue._id,
          email: newValue.email,
        });
      }
    } else {
      if (isProduction) {
        Sentry.setUser(null);
      }
    }
  });
};

const defaultConfigLoginState = selector<ConfigLogin>({
  key: 'configLoginState/default',
  get: () => {
    const action = '/api/config/login';

    return networkGet(action, {
      headers: {
        'app-id': APP_ID,
      },
    }) as Promise<ConfigLogin>;
  },
});

export const configLoginState = atom<ConfigLogin>({
  key: 'configLoginState',
  default: defaultConfigLoginState,
});

const defaultMaybeCurrentUserState = selector<User | null>({
  key: 'maybeCurrentUserState/default',
  async get() {
    try {
      const response = (await networkGet('/api/users/current')) as User;

      localStorage.setItem('user', JSON.stringify(response));

      if (isProduction) {
        Sentry.setUser({
          id: response._id,
          email: response.email,
        });
      }

      return response;
    } catch {
      localStorage.removeItem('user');

      if (isProduction) {
        Sentry.setUser(null);
      }

      return null;
    }
  },
});

export const maybeCurrentUserState = atom<User | null>({
  key: 'maybeCurrentUserState',
  default: defaultMaybeCurrentUserState,
  effects: [localStorageEffect('user'), sentryEffect],
});

export const currentUserState = selector<User>({
  key: 'currentUserState',
  get: ({ get }) => {
    const maybeCurrentUser = get(maybeCurrentUserState);

    if (!maybeCurrentUser) {
      throw new Error('currentUser is required');
    }

    return maybeCurrentUser;
  },
  set: ({ set }, newValue) => {
    set(maybeCurrentUserState, newValue);
  },
});

export const currentUserIdState = selector<string>({
  key: 'currentUserIdState',
  get: ({ get }) => {
    const currentUser = get(currentUserState);

    return currentUser._id;
  },
});

export const businessQuery = selectorFamily<Business | null, string | null | undefined>({
  key: 'business',
  get: (id) => () => {
    if (!id) {
      return null;
    }

    const action = `/api/businesses/${id}`;

    return networkGet(action) as Promise<Business>;
  },
});

export const businessCardsQuery = selectorFamily<Card[], string>({
  key: 'businessCards',
  get: (id) => () => {
    const action = `/api/businesses/${id}/cards`;

    return networkGet(action) as Promise<Card[]>;
  },
});

export const businessPlacesQuery = selectorFamily<
  (Place & { distance: number })[],
  {
    businessId: string;
    includePlacesWithoutLocation?: boolean;
    lat?: number;
    lng?: number;
    limit?: number;
  }
>({
  key: 'businessPlaces',
  get: (args) => () => {
    const { businessId, includePlacesWithoutLocation, lat, lng, limit } = args;

    let action = `/api/businesses/${businessId}/places`;
    if (lat && lng) {
      action += `${stringify({ includePlacesWithoutLocation, lat, lng, limit })}`;
    } else {
      action += `${stringify({ includePlacesWithoutLocation, limit })}`;
    }

    return networkGet(action) as Promise<(Place & { distance: number })[]>;
  },
});

const { itemQuery: cardQuery } = createSimpleState<Card>('card', { scopedRoute: false });
export { cardQuery };

const { itemsState: cardSubscriptionsState, itemQuery: cardSubscriptionQuery } =
  createSimpleState<CardSubscription>('card_subscription');
export { cardSubscriptionsState, cardSubscriptionQuery };

const { itemsState: challengesState, itemQuery: challengeQuery } = createSimpleState<Challenge>(
  'challenge',
  { scopedRoute: false },
);
export { challengesState, challengeQuery };

const asyncDefaultChallengeRankingQuery = selectorFamily<ChallengeRanking, string>({
  key: 'challengeRanking/default',
  get: (id) => () => {
    const action = `/api/challenges/${id}/ranking`;

    return networkGet(action) as Promise<ChallengeRanking>;
  },
});

export const challengeRankingQuery = atomFamily<ChallengeRanking, string>({
  key: 'challengeRanking',
  default: asyncDefaultChallengeRankingQuery,
});

const { itemsQuery: couponsQuery, itemQuery: couponQuery } = createState<
  CouponWithDynamicProperties,
  string | { cardId: string; id: string }
>('coupon', {
  getAction({ args }) {
    const cardId = args instanceof Object ? args.cardId : args;

    return `/api/cards/${cardId}/coupons`;
  },
});
export { couponsQuery, couponQuery };

const { itemsState: messagesState, itemQuery: messageQuery } =
  createSimpleState<Message>('message');
export { messagesState, messageQuery };

const { itemsQuery: offersQuery, itemQuery: offerQuery } = createState<
  Offer,
  string | { cardId: string; id: string }
>('offer', {
  getAction({ args }) {
    const cardId = args instanceof Object ? args.cardId : args;

    return `/api/cards/${cardId}/offers`;
  },
});
export { offersQuery, offerQuery };

function isActiveCardSubscription(
  cardSubscription: CardSubscription,
): cardSubscription is ActiveCardSubscription {
  return cardSubscription.active;
}

export const activeCardSubscriptionsState = selector<ActiveCardSubscription[]>({
  key: 'activeCardSubscriptionsState',
  get: ({ get }) => {
    const cardSubscriptions = get(cardSubscriptionsState);

    return cardSubscriptions.filter(isActiveCardSubscription);
  },
});

function isUnreadMessage(message: Message): message is UnreadMessage {
  return !message.is_read;
}

export const unreadMessagesCountState = selector<number>({
  key: 'unreadMessagesCountState',
  get: ({ get }) => {
    const messages = get(messagesState);

    return messages.filter(isUnreadMessage).length;
  },
});

const asyncDefaultPlaces = selectorFamily<
  Place[] | null,
  {
    lat?: number;
    lng?: number;
    search: string;
    categories: string;
    limit?: number;
  }
>({
  key: 'placesState/default',
  get: (args) => () => {
    if (!args.lat || !args.lng) {
      return null;
    }

    const { lat, lng, search, categories, limit } = args;

    let action = '/api/places';
    if (lat && lng) {
      action += `${stringify({
        lat,
        lng,
        search,
        categories,
        limit,
      })}`;
    } else {
      action += `${stringify({ search, categories, limit })}`;
    }

    return networkGet(action) as Promise<Place[]>;
  },
});

export const placesQuery = atomFamily<
  Place[] | null,
  {
    lat?: number;
    lng?: number;
    search: string;
    categories: string;
    limit?: number;
  }
>({
  key: 'placesState',
  default: asyncDefaultPlaces,
});

const asyncDefaultPlace = selectorFamily<Place, string>({
  key: 'placeState/default',
  get: (id) => () => {
    const action = `/api/places/${id}`;

    return networkGet(action) as Promise<Place>;
  },
});

export const placeQuery = atomFamily<Place, string>({
  key: 'placeState',
  default: asyncDefaultPlace,
});

const { itemsQuery: placeProductsQuery, itemQuery: placeProductQuery } = createState<
  Product,
  string | { placeId: string; id: string }
>('placeProduct', {
  getAction({ args }) {
    const placeId = args instanceof Object ? args.placeId : args;

    return `/api/places/${placeId}/products`;
  },
});
export { placeProductsQuery, placeProductQuery };

const { itemsQuery: rewardsQuery, itemQuery: rewardQuery } = createState<
  Reward,
  string | { cardId: string; id: string }
>('reward', {
  getAction({ args }) {
    const cardId = args instanceof Object ? args.cardId : args;

    return `/api/cards/${cardId}/rewards`;
  },
});
export { rewardsQuery, rewardQuery };

const { itemsQuery: transactionsQuery, itemQuery: transactionQuery } = createState<
  Transaction,
  string | { cardSubscriptionId: string; offset: number; limit: number }
>('transaction', {
  getAction({ args, get }) {
    const currentUserId = get(currentUserIdState);

    const argsObject = args instanceof Object ? args : { cardSubscriptionId: args };
    const { cardSubscriptionId, ...params } = argsObject;

    return `/api/users/${currentUserId}/card_subscriptions/${cardSubscriptionId}/transactions${stringify(
      params,
    )}`;
  },
});
export { transactionsQuery, transactionQuery };

export const cardBalanceQuery = selectorFamily<number, string>({
  key: 'balanceState',
  get:
    (cardSubscriptionId) =>
    ({ get }) => {
      const cardSubscription = get(cardSubscriptionQuery(cardSubscriptionId));

      return cardSubscription.balance;
    },
});

function getIsRewardTransaction(transaction: Transaction): transaction is RewardTransaction {
  return transaction.type === 'reward';
}

function getIsNotRedeemedRewardTransaction(
  transaction: Transaction,
): transaction is NotRedeemedRewardTransaction {
  return getIsRewardTransaction(transaction) && !transaction.redeemed;
}

export const redeemableTransactionsQuery = selectorFamily<
  RewardTransaction[],
  string | { cardSubscriptionId: string; offset: number; limit: number }
>({
  key: 'redeemableTransactionsQuery',
  get:
    (cardSubscriptionId) =>
    ({ get }) => {
      const transactions = get(transactionsQuery(cardSubscriptionId));

      return transactions.filter(getIsNotRedeemedRewardTransaction);
    },
});

export const pendingCouponRefreshesState = atom<string[]>({
  key: 'pendingCouponRefreshesState',
  default: [],
});

export const pendingRewardRefreshesState = atom<string[]>({
  key: 'pendingRewardRefreshesState',
  default: [],
});
