import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { useLocation } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web';
import { useWindowWidth } from '@wojtekmaj/react-hooks';
import T from '@wojtekmaj/react-t';

import { AwardIcon, HomeIcon, InboxIcon, MapIcon, ScanLineIcon } from 'lucide-react';

import {
  Wrapper,
  List,
  ListItem,
  ListItemLink,
  TabIndicator,
  Icon,
  Label,
  Badge,
} from './menu.styles';

import { unreadMessagesCountState } from '../../../recoil';

const AnimatedTabIndicator = animated(TabIndicator);

type Item = {
  label: string;
  icon: React.ComponentType;
  href: string;
  exact?: boolean;
};

const items: Item[] = [
  {
    label: 'Home',
    icon: HomeIcon,
    href: './',
    exact: true,
  },
  {
    label: 'Map',
    icon: MapIcon,
    href: 'map',
  },
  {
    label: 'Add stamps',
    icon: ScanLineIcon,
    href: 'camera',
  },
  {
    label: 'Messages',
    icon: InboxIcon,
    href: 'inbox',
  },
  {
    label: 'Challenges',
    icon: AwardIcon,
    href: 'challenges',
  },
];

export default function Menu() {
  const windowWidth = useWindowWidth();
  const location = useLocation();
  const unreadMessagesCountLoadable = useRecoilValueLoadable(unreadMessagesCountState);
  const tabWrapper = useRef<HTMLUListElement>(null);
  const shouldUseImmediate = useRef(true);
  const [indicatorRect, setIndicatorRect] = useState<object>({ left: 0, opacity: 1 });

  const props = useSpring(indicatorRect);

  const unreadMessagesCount = unreadMessagesCountLoadable.valueMaybe();

  const itemsWithBadge = useMemo<(Item & { badge?: number })[]>(
    () =>
      unreadMessagesCount
        ? items.map((item) =>
            item.href === 'inbox' ? { ...item, badge: unreadMessagesCount } : item,
          )
        : items,
    [unreadMessagesCount],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: useEffect intentionally triggered on window width change
  useEffect(() => {
    shouldUseImmediate.current = true;
  }, [windowWidth]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: useEffect intentionally triggered on location change
  useEffect(() => {
    if (!tabWrapper.current) {
      return;
    }

    const activeTab = tabWrapper.current.querySelector('.active');

    function getRect() {
      if (!tabWrapper.current) {
        return;
      }

      if (!activeTab) {
        setIndicatorRect({
          opacity: 0,
          immediate: true,
        });

        shouldUseImmediate.current = true;

        return;
      }

      const rect = activeTab.getBoundingClientRect();

      if (!rect.width && !rect.height) {
        requestAnimationFrame(getRect);
        return;
      }

      const parentRect = tabWrapper.current.getBoundingClientRect();

      const immediate = shouldUseImmediate.current;

      setIndicatorRect({
        left: rect.x - parentRect.x + rect.width / 2,
        opacity: 1,
        immediate,
      });

      shouldUseImmediate.current = false;
    }

    getRect();
  }, [location.pathname, windowWidth]);

  useEffect(() => {
    if (unreadMessagesCount) {
      if ('setAppBadge' in navigator) {
        navigator.setAppBadge(unreadMessagesCount);
      }
    } else {
      if ('clearAppBadge' in navigator) {
        navigator.clearAppBadge();
      }
    }
  }, [unreadMessagesCount]);

  return (
    <Wrapper>
      <List ref={tabWrapper} role="list">
        {itemsWithBadge.map((item) => {
          const IconComponent = item.icon;

          return (
            <ListItem key={item.label}>
              <ListItemLink
                className={({ isActive }) => (isActive ? 'active' : '')}
                to={item.href}
                end={item.exact}
              >
                <Icon>
                  <IconComponent />
                </Icon>
                <Label>
                  <T>{item.label}</T>
                </Label>
                {item.badge ? <Badge>{item.badge}</Badge> : null}
              </ListItemLink>
            </ListItem>
          );
        })}
      </List>
      <AnimatedTabIndicator style={props} />
    </Wrapper>
  );
}
