import { cloneElement, Component } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import * as Sentry from '@sentry/react';

import { maybeCurrentUserState } from '../recoil';

import { PROD } from '../env';

import type { Location } from 'react-router-dom';
import type { User } from '@rewardopl/types';

const isProduction = PROD;

type ErrorBoundaryProps = {
  children: React.ReactNode;
  currentUser: User | null;
  fallback?: React.ReactElement<{ resetError?: () => void }> | null;
  location: Location;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.resetError = this.resetError.bind(this);
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error(error, errorInfo);

    if (error instanceof Error && error.message === 'Forbidden') {
      const { currentUser } = this.props;

      if (currentUser) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
      return;
    }

    localStorage.clear();

    if (isProduction) {
      Sentry.captureException(error);
    }

    this.setState({ hasError: true });
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    const { location: prevLocation } = prevProps;

    if (prevLocation.pathname !== this.props.location.pathname) {
      this.resetError();
    }
  }

  resetError() {
    this.setState({ hasError: false });
  }

  render() {
    const { resetError } = this;
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback ? cloneElement(fallback, { resetError }) : null;
    }

    return children;
  }
}

function withCurrentUser<P extends { currentUser: User | null }>(
  InternalComponent: React.ComponentType<P>,
) {
  function WithCurrentUserInternal(props: Omit<P, 'currentUser'>) {
    const currentUser = useRecoilValueLoadable(maybeCurrentUserState);

    return <InternalComponent {...(props as P)} currentUser={currentUser.valueMaybe() || null} />;
  }

  WithCurrentUserInternal.displayName = `withCurrentUser(${InternalComponent.name})`;

  return WithCurrentUserInternal;
}

function withLocation<P extends { location: Location }>(InternalComponent: React.ComponentType<P>) {
  function WithLocationInternal(props: Omit<P, 'location'>) {
    const location = useLocation();

    return <InternalComponent {...(props as P)} location={location} />;
  }

  WithLocationInternal.displayName = `withLocation(${InternalComponent.name})`;

  return WithLocationInternal;
}

export default withLocation(withCurrentUser(ErrorBoundary));
