import styled from 'styled-components';

import avatarPlaceholder from '../../static/avatar-placeholder.svg';

const avatarPlaceholderUrl = `url("${avatarPlaceholder}")`;

export const Avatar = styled.img`
  display: block;
  background-image: ${avatarPlaceholderUrl};
  background-size: 100%;
  border: 4px solid var(--white);
  border-radius: var(--border-radius-round);
  overflow: hidden;
  color: transparent;
`;
