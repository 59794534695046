import { useRecoilValue } from 'recoil';

import { getIsRegistered } from '@rewardopl/utils/user';

import { currentUserState } from '../recoil';

export default function useIsRegistered(): boolean {
  const currentUser = useRecoilValue(currentUserState);

  return getIsRegistered(currentUser);
}
