import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  ${() =>
    navigator.standalone &&
    css`
      padding-bottom: 15px;
      padding-bottom: max(15px, env(safe-area-inset-bottom));
    `}
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'main' 'aside';

  position: fixed;
  inset: 0;

  @media screen and (min-width: 769px) {
    grid-template-rows: auto;
    grid-template-columns: 240px 1fr;
    grid-template-areas: 'aside main';
  }

  @media (screen-spanning: single-fold-vertical) {
    grid-template-rows: auto;
    grid-template-columns: env(fold-left) 1fr;
    gap: env(fold-width);
    grid-template-areas: 'aside main';
  }

  @media (horizontal-viewport-segments: 2) {
    grid-template-rows: auto;
    grid-template-columns: env(viewport-segment-width 0 0) env(viewport-segment-width 1 0);
    gap: calc(env(viewport-segment-left 1 0) - env(viewport-segment-right 0 0));
    grid-template-areas: 'aside main';
  }
`;
