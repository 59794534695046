import { useLocale, I18nProvider as ReactAriaI18nProvider } from 'react-aria-components';

import { defaultLocale, supportedLocales } from './i18n';

type I18nProviderProps = {
  children: React.ReactNode;
};

export default function I18nProvider({ children }: I18nProviderProps) {
  const { locale } = useLocale();

  const languageCode = locale.split('-')[0];

  const supportedLocale =
    languageCode && supportedLocales.includes(languageCode) ? languageCode : defaultLocale;

  return <ReactAriaI18nProvider locale={supportedLocale}>{children}</ReactAriaI18nProvider>;
}
