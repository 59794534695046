import { Link, Route, Routes, useLocation } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { PageWrapper, Main, FormWrapper } from './index.styles';

import Heading from '../../heading';
import Header from '../../header';
import Footer from '../../footer';

import LoginMain from './main';
import LoginEmail from './email';

export default function LoginPage() {
  const location = useLocation();

  return (
    <PageWrapper>
      <Header />
      <FormWrapper>
        <Main>
          <Heading align="center">Login</Heading>
          <Routes>
            <Route path="" element={<LoginMain />} />
            <Route path="email" element={<LoginEmail />} />
          </Routes>
          <p>
            <T>Don’t have an account yet?</T>{' '}
            <Link to="/register" state={location.state}>
              <T>Register</T>
            </Link>
            .
          </p>
        </Main>
      </FormWrapper>
      <Footer />
    </PageWrapper>
  );
}
