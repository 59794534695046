import { makeNumberArray } from '@rewardopl/utils/array';

import { Wrapper, Dot } from './carousel_navigation.styles';

import { scrollToChild } from '../utils/carousel';

type CarouselNavigationProps = {
  currentChildIndex: number;
  numberOfChildren: number;
  scrollContainer: React.RefObject<HTMLElement | null>;
};

export default function CarouselNavigation({
  currentChildIndex,
  numberOfChildren,
  scrollContainer,
}: CarouselNavigationProps) {
  return (
    <Wrapper>
      {makeNumberArray(numberOfChildren).map((number, index, arr) => (
        <Dot
          key={number}
          type="button"
          current={currentChildIndex === index}
          onClick={() => scrollToChild(index, scrollContainer.current)}
          title={`Scroll to slide ${number} of ${arr.length}`}
        />
      ))}
    </Wrapper>
  );
}
