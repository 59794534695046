import { currentUserIdState } from '../../recoil';

import type { GetRecoilValue } from 'recoil';

export default function defaultGetAction({
  get,
  keys,
  scopedRoute,
}: {
  get: GetRecoilValue;
  keys: string;
  scopedRoute?: boolean;
}) {
  const currentUserId = get(currentUserIdState);

  return `/api/${scopedRoute && currentUserId ? `users/${currentUserId}/` : ''}${keys}`;
}
