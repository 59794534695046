import styled from 'styled-components';

export const Wrapper = styled.footer`
  padding: var(--gap);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap);

  @media screen and (min-width: 769px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1100px) {
    flex-wrap: nowrap;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);

  a {
    white-space: nowrap;
  }

  @media screen and (min-width: 1100px) {
    flex-wrap: nowrap;
  }
`;

export const Copyright = styled.p`
  margin: 0;
  text-align: center;
`;
