import { Wrapper, Links, Copyright } from './footer.styles';

import TermsAndConditionsLink from './terms_and_conditions_link';
import PrivacyPolicyLink from './privacy_policy_link';
import ContactUsLink from './contact_us_link';

const currentYear = new Date().getFullYear();

export default function Footer() {
  return (
    <Wrapper>
      <Links>
        <TermsAndConditionsLink />
        <PrivacyPolicyLink />
        <ContactUsLink />
      </Links>
      <Copyright>{`© Rewardo sp. z o.o. 2020–${currentYear}`}</Copyright>
    </Wrapper>
  );
}
