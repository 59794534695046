import T from '@wojtekmaj/react-t';

import { Heading as HeadingElement } from './heading.styles';

import Title from './title';

type HeadingProps = {
  align?: 'left' | 'center' | 'right';
  children: string;
};

export default function Heading({ align = 'left', children }: HeadingProps) {
  return (
    <>
      <Title>{children}</Title>
      <HeadingElement align={align}>
        <T>{children}</T>
      </HeadingElement>
    </>
  );
}
