import T from '@wojtekmaj/react-t';
import { useLocation } from 'react-router-dom';

import { stringify } from '@rewardopl/utils/url';

import { Button, Icon } from './login_with_google_button.styles';

import googleIcon from '../../../../static/google.svg';

export default function LoginWithGoogleButton() {
  const location = useLocation();

  const { returnPath = '/' } = (location.state || {}) as {
    returnPath?: string;
  };

  return (
    <Button
      as="a"
      href={`/api/auth/google${stringify({
        state: encodeURIComponent(JSON.stringify({ mobile: false, returnPath })),
      })}`}
    >
      <Icon src={googleIcon} width={20} height={20} alt="Google" />
      <T>Sign in with Google</T>
    </Button>
  );
}
