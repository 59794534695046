import { Link, useLocation, useNavigate } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import Dialog from '@rewardopl/react-ui/dialog';

import ContactUs from './contact_us';

export default function ContactUsLink() {
  const location = useLocation();
  const navigate = useNavigate();

  const close = () => {
    navigate(
      {
        ...location,
        hash: '',
      },
      { state: location.state },
    );
  };

  return (
    <>
      <Link to="#contact-us">
        <T>Contact us</T>
      </Link>
      <Dialog isOpen={location.hash === '#contact-us'} onDismiss={close} title="Contact us">
        <ContactUs />
      </Dialog>
    </>
  );
}
